@import '../../variables.scss';

@mixin skillCard {
    width: 360px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    flex-direction: column;
    padding: 10px;
    img {
        height: 64px;
        margin: 10px;
    }
    h2 { 
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
    }
    p {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        width: 310px;
        text-align: center;
        height: 105px;
    }
    h3{
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
    }
    .images{

        display: flex;
        justify-content: center;
        flex-wrap: wrap;


    }

}


.AboutMe{
    .HeadDiv {
        padding: 20px;
        padding-bottom: 0px;
        margin-bottom: -0px;
        background-color: $primary500;
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #FFFFFF;
        h1 {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 44px;
        }
        p {
            max-width: 1030px;
            min-height: 155px;

            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 30px;
            line-height: 35px;
        }
    }

    .OrangeWaves {
        z-index: 3;
        position: relative;
        height: 400px;
        background-position: top;
        // background-size: max(100vw, 700px) max(30vh, 160px);
        background-size: 100vw 300px;
        background-repeat: no-repeat;
        margin-bottom: -400px;
    }

    .ComputerScience {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 150px;
        min-height: 725px;
        width: 100%;   
        // background-image: none !important; 
        

        h1 {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 58px;
            /* identical to box height */


            color: #E76F51;

            text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
            // backdrop-filter: blur(15px);
        }

        .Skills{
            background-color: #FFFFFF;
            width: 1080px;
            height: 450px;
            box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
            border-radius: 25px;
            display: flex;
            font-family: Roboto;

            

            .scProg{
                @include skillCard();
            }

            .webDev {
                @include skillCard();
                .reactImg {
                    margin-left: -10px;
                    margin-right: -10px;
                }
                .nodeImg{
                    height: 50px !important;
                }
            }

            .tools {       
                @include skillCard();
                padding-left: 20px;
                padding-right: 20px;
                .linuxImg {
                    
                }
            }
        


        }

    }

    .transi {
        height: 150px;
        margin-top: -75px;
        margin-bottom: -75px;
        z-index: 3;
        position: relative;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.546392) 42.19%, #FFFFFF 50.52%, rgba(255, 255, 255, 0.736842) 59.9%, rgba(255, 255, 255, 0) 100%);
    }

    .Creative {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;
        min-height: 650px;
        width: 100%;
        // background-size: 73px;
        // background-image: none !important;
        background-position: 0px -125px;


        h1 {
            font-family: Inter;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 58px;
            z-index: 4;
            position: relative;
            /* identical to box height */


            color: #0073BF;

            text-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);
            // backdrop-filter: blur(15px);
        }

        .Skills {
            background-color: #FFFFFF;
            width: 1080px;
            height: 450px;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 25px;
            display: flex;
            font-family: Roboto;
        


            .design{
                @include skillCard();
                .figmaImg{
                    margin-left: 80px;
                    margin-right: 80px;
                }
                // padding-left: 20px;
                // padding-right: 20px;
            }

            .languages {
                @include skillCard();
                p{
                    margin-bottom: -10px;
                }
                .images {
                    display: flex;
                    flex-direction: column;
                    img{
                        height: 30px;
                    }
                }
            }
            .human {       
                @include skillCard();
                
                p {
                    margin-bottom: -25px;
                }

                .images {
                    display: flex;
                    flex-direction: column;
                    img{
                        height: 48px;
                    }
                }
            }
        }


    }

    .Bluewaves {
        z-index: 3;
        position: relative;
        height: 400px;
        background-position: bottom;
        background-size: 100vw 300px;
        background-repeat: no-repeat;
        margin-top: -325px;
    }

}

//MOBILE
@media screen and (max-width: 1200px) {

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 36px !important;
        line-height: 44px !important;
        text-align: center !important;
        max-width: 300px !important;
    }

    .Skills {
        width: 360px !important;
        height: 1350px !important;
        flex-wrap: wrap;
    }

    .ComputerScience {
        min-height: 1700px !important;
    }

    .Creative {
        min-height: 1575px !important;
    }

    .separator {
        transform: rotate(90deg);
        margin-left: auto;
        margin-right: auto;
        margin-top: -150px;
        margin-bottom: -150px;
    }

}