@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Roboto:wght@400;500;600&display=swap");

$primary500: #e76f51;
$gray: #464646;

// font-family: 'Caveat', cursive;

.html {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
}

.HomeSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  max-height: -webkit-fill-available; //patch du bug vh sur ios

  .Content {
    display: grid;

    .Text {
      -webkit-perspective: 1500;
      // perspective: 1000;
      h2 {
        // -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        //                 animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
        -webkit-animation: slide-in-fwd-center 0.4s
          cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
        animation: slide-in-fwd-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          both;
      }
    }

    .Links {
      button,
      a {
        z-index: 5;
        text-decoration: none;
        text-align: center;
        text-justify: auto;
        justify-content: center;
        align-items: center;
        display: flex;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        text-align: center;
        height: 64px;
        width: 140px;
        border: 2px solid black;
        border-radius: 10px;
        background-color: transparent;
        color: black;

        margin-top: 70px;
      }
    }
  }

  .Footer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    background-position: bottom;
    background-size: max(100vw, 700px) max(30vh, 160px);
    background-repeat: no-repeat;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: white;

    div {
      display: flex;
      flex-direction: column;
      img {
        margin-top: -25px;
      }
    }
  }
}

// low-height
@media screen and (max-height: 775px) and (max-width: 1200px) {
  .Links {
    button,
    a {
      height: 50px !important;
    }

    .Link {
      margin-top: -25px !important;
      #school {
        width: 151px !important;
      }

      #code {
        width: 120px !important;
      }
      #pro {
        width: 121px !important;
        transform: translate(40px, 40px) !important;
      }
    }
  }

  .RightRow {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
    img {
      height: 200px !important;
    }
  }

  .Footer {
    font-size: 18px !important;
  }
}

// Tel
@media screen and (max-width: 1200px) {
    .Content {
      grid-template-columns: auto auto auto;
      grid-template-rows: auto;
      .Text {
        margin-top: 20px;
        margin-bottom: 10px;
        grid-row: 1;
        grid-column: 1;

        h2 {
          font-size: 32px;
          line-height: 37px;
          span {
            color: $primary500;
            span {
              font-weight: 500;
              font-size: 38px;
            }
          }
        }

        p {
          font-size: 18px;
          color: $gray;
          line-height: 21px;
        }
      }

      .Links {
        grid-row: 3;
        margin-top: -20px;

        display: flex;
        flex-direction: column;
        // align-items: center;
        justify-content: space-around;

        .Link {
          display: flex;
          justify-content: space-between;
          margin-top: -15px;
          // width: 140px;

          height: 100px;

          button,
          a {
            height: 64px;
            order: 2;
          }

          #school {
            width: 189px;
            order: 1;
            transform: translate(-5px, 50px);
          }

          #code {
            width: 148px;
            order: 3;
            transform: translate(-35px, 50px);
          }
          #pro {
            width: 151px;
            order: 1;
            transform: translate(30px, 60px);
          }
        }
      }

      .RightRow {
        grid-column: 1;
        grid-row: 2;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 255px;
        }
      }
    }
  }

// PC
@media screen and (min-width: 1200px) {
  .Content {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    margin-top: 20vh;

    .Text {
      grid-row: 1;
      grid-column: 1;

      h2 {
        font-size: 75px;
        line-height: 88px;
        span {
          color: $primary500;
          span {
            font-weight: 500;
            font-size: 80px;
          }
        }
      }

      p {
        font-size: 36px;
        color: $gray;
        line-height: 42px;
      }
    }

    .Links {
      grid-column: 1;
      grid-row: 2;

      display: flex;
      align-items: center;
      justify-content: space-around;

      .Link {
        display: flex;
        flex-direction: column;
        width: 140px;

        height: 200px;

        button,
        a {
          height: 64px;
        }

        #school {
          width: 171.7px;
          transform: translate(-50px, 10px);
        }

        #code {
          width: 139px;
          transform: translate(50px, -143px);
        }
        #pro {
          width: 158px;
          transform: translate(20px, 8px);
        }
      }
    }

    .RightRow {
      grid-column: 2;
      grid-row: 1 / span 2;
      width: auto;
      padding-left: 30px;
      img {
        height: 417px;
      }
    }
  }
}

/**
 * ----------------------------------------
 * animation slide-in-fwd-center
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
