@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Roboto:wght@400;500;600&display=swap");

$primary500: #e76f51;
$gray: #464646;

html {
  // font-family: "Roboto", sans-serif;
  // font-style: normal;
  // font-weight: normal;
  // font-size: 24px;
  // line-height: 29px;
  overflow: overlay;
}

// html::-webkit-scrollbar {
//   width: 10px;
// }

// /* Track */
// html::-webkit-scrollbar-track {
//   background: none;
// }

// /* Handle */
// html::-webkit-scrollbar-thumb {
//   background: rgba(136, 136, 136, 0.5);
//   background-blend-mode: color;
//   border: 2px solid transparent;
//   width: 8px;
//   border-radius: 7px;
// }

// /* Handle on hover */
// html::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

html {
  ::-webkit-scrollbar {
    width: 14px;
    height: 18px;
}
::-webkit-scrollbar-thumb {
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    -webkit-border-radius: 7px;
    background-color: rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}
::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}
}


.App {

}
